import React from "react";
import authRoles from "app/auth/auth_roles";

const policyConfig = {
    auth:authRoles.onlyGuest,
    routes: [
        {
            path: "/chinh-sach-bao-mat",
            exact:true,
            component: React.lazy(() => import("./PolicyPage")),
        },
        {
            path: "/tro-ly-nha-nong/chinh-sach-bao-mat",
            exact:true,
            component: React.lazy(() => import("./TLNNPolicyPage")),
        },
    ],
};
export default policyConfig;
